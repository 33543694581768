.layerPreview {
  height: inherit;
  width: 27vw;
  overflow: scroll;
  flex: 24%;
  // color: white;
  border-right:  1px solid var(--border-color);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  section {
    img {
      display: block;
      height: 300px;
    }
  }
  .sketch-picker {
    background-color: transparent !important;
    box-shadow: none !important;
    width: auto !important;
    input {
      background-color: var(--light-grey-color);
      box-shadow: none !important;
    }
    label {
      display: none !important;
    }
    .flexbox-fix:last-child {
      display: none !important;
    }
  }
  .fieldName {
    width: 85%;
    // text-transform: capitalize;
  }
  &:-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
