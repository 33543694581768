// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// themes
@import "./themes/default.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  .title {
    @apply  text-lg font-semibold;
  }
  .field {
    @apply py-2 px-2 bg-lightGrey rounded-lg outline-0 border-none;
  }
}
.plusIcon {
  width: 26px;
  height: 26px;
  border: 1px solid #8c8686;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  svg {
    color: #8c8686;
    position: absolute;
    left: 0px;
    top: 0px;

  }
}
.mainContainer
{
  background: #fafafa;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(76, 76, 76, 0.309);
  & > * {
    position: absolute;
    top: 45%;
    left: 49%;
    color: var(--loader-bg-color) !important
  }
}
.mainContainer
{
  height: calc(100vh - 69px);
  overflow: scroll;
}
@mixin dividerPeudoClassValues {
  content: "";
  width: 45%;
  height: 1px;
  position: absolute;
  background-color: grey;
  top: 53%;
}
.orDivider {
  margin: 0;
  padding: 0;
  color: rgb(114, 111, 111);
  &:after {
    @include dividerPeudoClassValues();
    right: 0;
  }
  &::before {
    @include dividerPeudoClassValues();
    left: 0;
  }
}
.MuiButtonBase-root {
  min-height: 48px !important;
}
