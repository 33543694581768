.layersContainer {
  min-width: 200px;
  height: inherit;
  width: 26vw;
  border-right:  1px solid var(--border-color);
  .layers {
    overflow: scroll;
    height: calc(100vh - 223px);
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    .activeLayer {
      border: 2px solid var(--btn-bg-color);
      background-color: #f0f8ff;
    }
    .layerTitle {
      color: var(--btn-bg-color);
      font-weight: 500;
      font-size: 14px;
    }
    input {
      padding: 3px 8px;
      width: 95%;
      border: none;
      outline: none;
      
      // color: white;
      // caret-color: white;
      font-size: 16px;
    }
    // p {

    //   margin-top: -6px;
    //   margin-right: 3px;
    // }
    .addAttr {
      font-size: 16px;
      color: rgb(163, 161, 161);
    }
    .MuiAccordionSummary-expandIconWrapper {
      // color: white;
    }
    .baseImgthumbnail {
      width: 20px;
      height: 20px;
      // border: 1px solid white;
      border: 0.5px solid rgb(62, 60, 60);
      border-radius: 5px;
    }
    .deleteIcon {
      color: var(--warning-bg-color);
    }
    .cancelIcon {
      position: absolute;
      top: -9px;
      color: #b6b6b6;
      right: -9px;
      font-size: 15px;
    }
    &:-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}
