.collectionDetails,
.collectionForm {
  // padding: 10px 0px;
  // color: white;
  // background: white;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  .select {
    .MuiSvgIcon-root {
      // fill: white;
    }
    .MuiSelect-select {
      // color: white;
    }
  }

  .MuiFilledInput-root {
    height: 50px;
    border-radius: 7px;
    font-size: 15px;
    // color: white !important;

    &:hover {
      background-color: inherit;
    }
  }
  .MuiInputLabel-root {
    // color: darkgray;
  }
  .MuiDialog-paper {
    max-width: 330px;
    // color: white;
    background-color: var(--light-grey-color);
  }

  input {
    width: 100%;
    padding: 8px 9px;
    margin: 6px 0px;
    border: none;
    outline: none;
    // background: #2e2d2d;
    // color: white;
    // caret-color: white;
    font-size: 14px;
    border-radius: 7px;
  }
  .collectionDetailsLabel
  {
    @apply text-center w-[200px] py-2 text-black  field;
  }
  &:not(.collectionForm)
  {
    border-bottom:  1px solid var(--border-color);
  }
  .btn
  {
    padding: 0px 10px;
    border: 1px solid var(--main-bg-color);
    color: var(--main-bg-color)
  }
}
