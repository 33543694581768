.nftCollection {
  height: calc(100vh - 517px);
  overflow: scroll;
  margin-top: 30px;

  .nft {
    padding: 5px;
    border: 2px solid #e7edf3;
    border-radius: 5px;
    position: relative;
    // width: 23%;
    & input[type="checkbox"]
    {
      width: 15px;
      height: 15px;
      position: absolute;
      left: 10px;
      top: 10px;
    }
    &:hover {
      border: 2px solid var(--btn-bg-color);
      cursor: pointer;
    }
  }

  .disableDownloadOpt
  {
    pointer-events: none;
    color: rgb(183, 176, 176);
    cursor: not-allowed;
  }
}
