.modalPopup {

  .MuiDialog-paper
{
  background-color: var(--light-grey-color) !important;
  // color: white !important;
}
}
.MuiDialog-paper
{
  button {
    font-size: 13px;
    text-transform: uppercase;
    padding: 8px 12px;
    border-radius: 5px;
    font-weight: 700;
    margin: 0px 9px 8px 0px;
    outline: none;
    border: none;
    &:first-child {
      // background-color: rgb(99 98 98) !important;
      // color: white;
      border: 1px solid grey;
    }
    &:last-child {
      background-color: var(--btn-bg-color) !important;
      color: white;
      margin-left: 5px;
    }
  }
}