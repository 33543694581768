.imgPreview
{
  .imgPreview
  {
    // width: 310px;
    // height: 310px;
    margin: 17px auto 17px auto;
    padding: 10px;
    box-sizing: border-box;
    object-fit: contain;
    border: 0.3px solid rgb(100, 99, 99);
    background-color: white;
  }
}