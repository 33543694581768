:root {


  --main-bg-color: #007bff;
  //background colors
  --bg-color: white;
  --light-grey-color:#e9ebee;
  --warning-bg-color : #ff7d44;
  --active-color: #413630;
  --layers-bg-color:rgba(240,248,255,.47843137254901963) ;

  //border colors
  --border-color:  #9eb8d2;

  //shadow colors
  --shadow-color: #595959;

  // text colors
  --txt-color: var(--main-bg-color);

  // button colors
  --btn-bg-color: var(--main-bg-color);

  //loeader 
--loader-bg-color : var(--main-bg-color)

}
