$workspaceWidth: 46vw;
$canvasWidth: 50%;
$canvasHeight: 48%;

.workspace {
  height: inherit;
  width : 49vw;
  padding: 21px 20px;
  box-sizing: border-box;
  // color: white;
  overflow: scroll;
  .ReactCrop {
    width: $canvasWidth;
    height: $canvasHeight;
    background-color: white;
    display: block;
    margin: 0 auto;
    // box-shadow: 0px 0px 20px grey;
    border-radius: 4px;
    border: 0.3px solid rgb(192, 189, 189);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    & > *:first-child
    {
      height: 100%;
      width: 100%;
    }
    & div > img {
      width: 100%;
      height: 100%;
    }
    .gridContainer {
      width: $canvasWidth;
      height: $canvasHeight;
      position: absolute;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      pointer-events: none;
    }
    .ReactCrop__crop-selection {
      box-shadow: 0 0 0 9999em rgb(48 48 48 / 50%);
    }
  }
}
